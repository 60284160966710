import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-700128a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "asAlertRenewCourseMobile_wrapper" }
const _hoisted_2 = { class: "asAlertRenewCourseMobile_text" }
const _hoisted_3 = { class: "asAlertRenewCourseMobile_btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_renew_course_mobile = _resolveComponent("as-renew-course-mobile")!

  return (_ctx.showAlert)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["asAlertRenewCourseMobile", {
      '-critical': _ctx.isCritical,
      '-warning': _ctx.isWarning
    }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.text), 1),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.isShowRenewBtn)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  "data-analytics": "",
                  "data-analytics-element-type": "other",
                  "data-analytics-element-id": "prolongation",
                  class: "asAlertRenewCourseMobile_renew",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlerRenew && _ctx.handlerRenew(...args)))
                }, _toDisplayString(_ctx.$t('renew')), 1))
              : _createCommentVNode("", true),
            (_ctx.isWarning)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  "data-analytics": "",
                  "data-analytics-element-type": "other",
                  "data-analytics-element-id": "closeAlertRenew",
                  class: "asAlertRenewCourseMobile_close",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.showRenewCourse)
          ? (_openBlock(), _createBlock(_component_as_renew_course_mobile, {
              key: 0,
              stream: _ctx.stream,
              onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showRenewCourse = false))
            }, null, 8, ["stream"]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}