import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0002f3ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "acMenu" }
const _hoisted_2 = {
  key: 0,
  class: "acMenu_menu",
  ref: "refMenu"
}
const _hoisted_3 = { class: "acMenu_content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!
  const _directive_attache_to_element = _resolveDirective("attache-to-element")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "acMenu_activator",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onToggleMenu && _ctx.onToggleMenu(...args))),
      ref: "refActivator"
    }, [
      _renderSlot(_ctx.$slots, "activator", {}, undefined, true)
    ], 512),
    (_ctx.isShowHandler)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ])), [
          [_directive_click_outside, _ctx.onCloseMenu],
          [_directive_attache_to_element, { element: _ctx.refActivator, location: _ctx.location }]
        ])
      : _createCommentVNode("", true)
  ]))
}