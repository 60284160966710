
import { computed, defineComponent, PropType } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@web/store'
import { Stream } from '@web/store/types/modules/stream'

import AcBottomNavigation from '@ui-kit/components/AcBottomNavigation.vue'
import AcButton from '@ui-kit/components/AcButton.vue'

export default defineComponent({
  name: 'BottomNavigation',
  components: {
    AcBottomNavigation,
    AcButton
  },
  props: {
    stream: {
      type: Object as PropType<Stream>,
      required: true
    },
    course: {
      type: Object as PropType<Course>,
      required: true
    },
    currentPage: {
      type: String as PropType<'course' | 'materials' | 'chat'>,
      default: 'course'
    }
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const chatId = computed(() => props.stream?.chatChannelId)
    const isShowBtnChat = computed(() => props.course?.isExpertMessageEnable)

    function goToCalendar () {
      store.commit('router/setReturnUrl', route.fullPath)
      router.push({
        name: 'calendar',
        params: {
          courseId: String(props.stream?.courseId),
          streamId: String(props.stream?.id)
        }
      })
    }

    function goToMaterials () {
      store.commit('router/setReturnUrl', route.fullPath)
      router.push({
        name: 'materials',
        params: {
          streamId: String(props.stream?.id)
        }
      })
    }

    function goToChat () {
      store.commit('router/setReturnUrl', route.fullPath)
      router.push({
        name: 'chat',
        params: {
          chatId: chatId.value
        }
      })
    }

    return {
      isShowBtnChat,
      goToCalendar,
      goToMaterials,
      goToChat
    }
  }
})
