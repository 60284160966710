import type { DirectiveBinding } from 'vue'

export enum Location {
  top = 'top',
  topLeft = 'top-left',
  topRight = 'top-right',
  bottom = 'bottom',
  bottomLeft = 'bottom-left',
  bottomRight = 'bottom-right',
  left = 'left',
  right = 'right'
}
interface AttacheToElementBindingValue {
  location?: Location.top | Location.topLeft | Location.topRight | Location.bottom | Location.bottomLeft | Location.bottomRight | Location.left | Location.right;
  element: HTMLElement;
  global?: boolean;
}

export const AttacheToElement = {
  mounted (el: HTMLElement, binding: DirectiveBinding<AttacheToElementBindingValue>) {
    if (!binding.value.element) {
      return
    }

    const location = binding.value.location || 'bottom'

    setTimeout(() => {
      const boundingClientRect = el.getBoundingClientRect()
      const elementBoundingClientRect = binding.value.element.getBoundingClientRect()

      if (binding.value.global) {
        el.style.position = 'fixed'
        if (location === Location.top) {
          el.style.top = `${elementBoundingClientRect.y - boundingClientRect.height}px`
          el.style.left = `${elementBoundingClientRect.x}px`
        }
        if (location === Location.bottom) {
          el.style.top = `${elementBoundingClientRect.y + elementBoundingClientRect.height}px`
          el.style.left = `${elementBoundingClientRect.x}px`
        }

        if (location === Location.left) {
          el.style.top = `${elementBoundingClientRect.y}px`
          el.style.right = `${window.innerWidth - elementBoundingClientRect.x - (elementBoundingClientRect.width / 2)}px`
        }
        if (location === Location.right) {
          el.style.top = `${elementBoundingClientRect.y}px`
          el.style.left = `${elementBoundingClientRect.x + elementBoundingClientRect.width}px`
        }
      } else {
        el.style.position = 'absolute'
        if (location === Location.top) {
          el.style.bottom = '100%'
          el.style.left = '0'
        }
        if (location === Location.topLeft) {
          el.style.bottom = '100%'
          el.style.right = '100%'
        }
        if (location === Location.topRight) {
          el.style.bottom = '100%'
          el.style.left = '100%'
        }
        if (location === Location.bottom) {
          el.style.top = '100%'
          el.style.right = '0'
        }
        if (location === Location.bottomLeft) {
          el.style.top = '100%'
          el.style.right = '100%'
        }
        if (location === Location.bottomRight) {
          el.style.top = '100%'
          el.style.left = '100%'
        }

        if (location === Location.left) {
          el.style.top = '0'
          el.style.right = '100%'
        }
        if (location === Location.right) {
          el.style.top = '0'
          el.style.left = '100%'
        }
      }
    }, 0)
  }
}

export default AttacheToElement
