
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AcProgress',
  props: {
    showText: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Number,
      default: 0
    }
  }
})
