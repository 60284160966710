
import { computed, defineComponent } from 'vue'

import i18n from '@web/plugins/i18n'
import { prettyHoursMinutes, getNumberDecliner } from '@web/common/Utils'
import { useStore } from '@web/store'

import DAY_STATUS from '@web/consts/DayStatus'
import HOMEWORK_STATUS from '@web/consts/HomeworkStatus'
import STAGE_STATUS from '@web/consts/StageStatus'

import AcPoints from '@web/components.v2/PointsLabel.vue'

export default defineComponent({
  name: 'CalendarHomework',
  components: {
    AcPoints
  },
  props: {
    homeworkStatus: {
      type: Object,
      default: () => ({})
    },
    homework: {
      type: Object,
      default: null
    },
    dayStatus: {
      type: Object,
      default: null
    },
    stageStatus: {
      type: String,
      default: STAGE_STATUS.CLOSE
    },
    gamificationEnabled: {
      type: Boolean,
      default: false
    },
    streamId: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    const store = useStore()

    const isGamificationEnabled = computed(() => props.gamificationEnabled)
    const isDisabledStage = computed(() => {
      return props.stageStatus === STAGE_STATUS.CLOSE
    })
    const dayIsDone = computed(() => {
      if (props.homeworkStatus.targetType === 'stage_day_homework') {
        return props.dayStatus?.status === DAY_STATUS.ENDED
      }
      return !isDisabledStage.value
    })
    const localHomework = computed(() => props.homework || store.getters['homework/getHomeworkByStatusId'](props.homeworkStatus.id))
    const isDone = computed(() => props.homeworkStatus?.status === HOMEWORK_STATUS.DONE)
    const scores = computed<Score[]>(() => Object.values(localHomework.value.homework?.scores || {}))
    const accruedScores = computed<AccruedScore[]>(() => Object.values(props.homeworkStatus.scores || {}))
    const points = computed(() => scores.value.reduce((count, score) => count + (score.isEnabled ? score.amount : 0), 0) || 0)
    const accruedPoints = computed(() => {
      return accruedScores.value.reduce((count, aScore) => {
        const isEnabled = scores.value.find(score => score.type === aScore.type)?.isEnabled || false
        return count + (isEnabled ? aScore.amount : 0)
      }, 0)
    })
    const status = computed<'disabled' | 'enabled' | 'done'>(() => {
      if (dayIsDone.value) {
        if (isDone.value) {
          return 'done'
        }
        return accruedPoints.value === points.value && accruedPoints.value > 0 ? 'done' : 'enabled'
      }

      return 'disabled'
    })
    const isDisabled = computed(() => !dayIsDone.value || isDisabledStage.value)
    const homeworkLink = computed(() => {
      if (isDisabled.value || !localHomework.value?.status?.id) {
        return ''
      }

      return {
        name: 'homework',
        params: {
          homeworkId: localHomework.value?.status?.id,
          streamId: props.streamId
        }
      }
    })

    const statusText = computed(() => {
      return {
        done: i18n.global.t('calendarPage.done'),
        enabled: i18n.global.t('calendarPage.available'),
        disabled: i18n.global.t('calendarPage.close')
      }[status.value] || i18n.global.t('calendarPage.close')
    })

    const pointsText = computed(() => {
      if (isGamificationEnabled.value) {
        let text = getNumberDecliner({
          n: points.value,
          single: i18n.global.t('pointsDecliner.point'),
          multiple: i18n.global.t('pointsDecliner.points'),
          multiple2: i18n.global.t('pointsDecliner.points2')
        })

        if (accruedPoints.value) {
          text = getNumberDecliner({
            n: accruedPoints.value,
            single: i18n.global.t('pointsDecliner.point'),
            multiple: i18n.global.t('pointsDecliner.points'),
            multiple2: i18n.global.t('pointsDecliner.points2')
          })
          if (accruedPoints.value === points.value) {
            return `${accruedPoints.value} ${text}`
          }
          return `${accruedPoints.value}/${points.value} ${text}`
        } else if (isDone.value) {
          return ''
        }

        return `${points.value} ${text}`
      }

      return ''
    })

    const isShowPoints = computed(() => pointsText.value && points.value)

    const homeworkType = computed<'course_stage_homework' | 'stage_day_homework'>(() => props.homeworkStatus.targetType)

    return {
      isDisabledStage,
      localHomework,
      isDone,
      statusText,
      pointsText,
      status,
      isDisabled,
      homeworkLink,
      isShowPoints,
      homeworkType,
      prettyHoursMinutes
    }
  }
})
