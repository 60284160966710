
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'CalendarDays',
  props: {
    status: {
      type: String as PropType<'open' | 'close'>,
      default: 'close'
    }
  }
})
