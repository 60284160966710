
import { defineComponent, ref, computed, watch } from 'vue'

import AcButton from './AcButton.vue'
import AcProgress from './AcProgress.vue'

// TODO В новом дизайне, все что связано с ui-kit должно лежать в папке ui-kit
import LocalStorage from '@web/common/LocalStorage'

export default defineComponent({
  components: {
    AcProgress,
    AcButton
  },
  props: {
    steps: {
      type: Array,
      default: () => []
    },
    timeout: {
      type: Number,
      default: 5
    },
    streamId: {
      type: Number,
      required: true
    }
  },
  emits: ['next', 'prev', 'end'],
  setup (props, ctx) {
    const showOnBoarding = ref(true)
    const currentStep = ref(0)
    const progress = ref<number[]>([])
    const refStepsContainer = ref<HTMLElement | null>(null)
    const refStepsWrapper = ref<HTMLElement | null>(null)
    const timerId = ref<number | undefined>(undefined)

    const isDisabledPrevBtn = computed(() => currentStep.value < 1)

    function onPrevStep () {
      stopTimer()
      progress.value[currentStep.value] = 0
      if (currentStep.value < 1) {
        currentStep.value = 0
        return
      }

      currentStep.value = currentStep.value - 1
      scrollToActiveStep()
      ctx.emit('prev', currentStep.value)
      startTimer()
    }

    function onNextStep () {
      stopTimer()
      progress.value[currentStep.value] = 100

      if (props.steps?.length - 1 === currentStep.value) {
        ctx.emit('end')
        showOnBoarding.value = false
        LocalStorage.set(`acProject_onBoarding_${props.streamId}`, 'true')
      } else {
        currentStep.value = currentStep.value + 1
        scrollToActiveStep()
        startTimer()
        ctx.emit('next', currentStep.value)
      }
    }

    function scrollToActiveStep () {
      if (!refStepsContainer.value || !refStepsWrapper.value) {
        return
      }

      const stepWidth = refStepsContainer.value.clientWidth
      refStepsWrapper.value.style.left = `-${currentStep.value * stepWidth}px`
    }

    function stopTimer () {
      clearInterval(timerId.value)
    }

    function startTimer () {
      progress.value[currentStep.value] = 0
      timerId.value = setInterval(() => {
        if (progress.value[currentStep.value] > 100) {
          stopTimer()
          onNextStep()
        }

        progress.value[currentStep.value] = progress.value[currentStep.value] + 1
      }, props.timeout * 10)
    }

    startTimer()

    watch(() => props.steps, (nSteps) => {
      if (showOnBoarding.value) {
        showOnBoarding.value = !!nSteps.length
      }
    }, {
      immediate: true
    })

    if (LocalStorage.get(`acProject_onBoarding_${props.streamId}`)) {
      showOnBoarding.value = false
    }

    return {
      showOnBoarding,
      currentStep,
      isDisabledPrevBtn,
      refStepsContainer,
      refStepsWrapper,
      progress,
      onPrevStep,
      onNextStep
    }
  }
})
