
import { defineComponent, ref, computed } from 'vue'
import { MqResponsive } from 'vue3-mq'

import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsRow from '@web/components/AsRow.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import AsBanner from '@web/components/AsBanner.vue'
import AsBannersTop from '@web/components/AsBannersTop.vue'
import AcCalendar from '@web/components.v2/Calendar/index.vue'
import BottomNavigation from '@web/components.v2/BottomNavigation.vue'

import i18n from '@web/plugins/i18n'

import useCalendar from '@web/composition/useCalendar'

import { useRouter } from 'vue-router'
import { useMeta } from '@web/common/vueMeta'
import AsAlertRenewCourseMobile from '@web/components/AsAlertRenewCourseMobile.vue'
import PAGE_NAME from '@web/consts/PAGE_NAME'

export default defineComponent({
  name: 'Calendar',
  setup () {
    const router = useRouter()

    const title = ref(i18n.global.t('scheduleCourse'))
    const menuMini = ref(false)
    const status = ref('')
    const isOpenMenu = ref(false)

    useMeta({
      title
    })

    const {
      menu,
      course,
      stream,
      bannersTop,
      bannersSidebar,
      bannersBottom,
      bannersBeforeStage,
      bannersPlatforms,
      bannersPlacements,
      bannersPosition
    } = useCalendar({ router })

    const showChatBtn = computed(() => stream.value?.chatChannelId)
    function goToChat () {
      if (stream.value) {
        router.push({
          name: 'chat',
          params: {
            chatId: stream.value?.chatChannelId
          }
        })
      }
    }

    function goBack () {
      router.push({ name: PAGE_NAME.HOME })
    }

    return {
      title,
      menuMini,
      status,
      isOpenMenu,
      menu,
      course,
      stream,
      bannersTop,
      bannersSidebar,
      bannersBottom,
      bannersBeforeStage,
      bannersPlatforms,
      bannersPlacements,
      bannersPosition,
      showChatBtn,
      goToChat,
      goBack
    }
  },
  components: {
    BottomNavigation,
    AsAlertRenewCourseMobile,
    AcCalendar,
    MqResponsive,
    AsHeaderMobile,
    AsRow,
    AsHeaderMain,
    AsMenu,
    AsSpacer,
    AsFooter,
    AsBreadcrumb,
    AsBanner,
    AsBannersTop
  }
})
