
import { defineComponent } from 'vue'

import AsRenewCourseMobile from '@web/components/AsRenewCourseMobile.vue'

import useStatusRenew from '@web/composition/useStatusRenew'
import useAlertRenewCourseMobile, { emits } from '@web/composition/useAlertRenewCourseMobile'
import { Stream } from '@web/store/types/modules/stream'

export default defineComponent({
  props: {
    stream: {
      type: Object as () => Stream,
      required: true
    }
  },
  components: {
    AsRenewCourseMobile
  },
  emits,
  setup (props, ctx) {
    const {
      isCritical,
      isWarning
    } = useStatusRenew(props.stream)

    const {
      checkShowAlert,
      ...data
    } = useAlertRenewCourseMobile({
      stream: props.stream,
      isCritical,
      isWarning
    }, ctx.emit)

    checkShowAlert()

    return {
      ...data,
      isCritical,
      isWarning
    }
  }
})
