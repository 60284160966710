
import { computed, defineComponent } from 'vue'

import { prettyHoursMinutes, getNumberDecliner } from '@web/common/Utils'
import i18n from '@web/plugins/i18n'

import DAY_STATUS from '@web/consts/DayStatus'
import STAGE_STATUS from '@web/consts/StageStatus'

import AcPoints from '@web/components.v2/PointsLabel.vue'

export default defineComponent({
  name: 'CalendarDay',
  components: {
    AcPoints
  },
  props: {
    day: {
      type: Object,
      required: true
    },
    stageStatus: {
      type: String,
      default: STAGE_STATUS.CLOSE
    }
  },
  setup (props) {
    const isDisabledStage = computed(() => props.stageStatus === STAGE_STATUS.CLOSE)
    const isDisabled = computed(() => props.day.isDisabled || isDisabledStage.value)
    const isDone = computed(() => props.day.status?.status === DAY_STATUS.ENDED)
    const isOpen = computed(() => props.day.isOpen && !isDisabledStage.value)
    const isClose = computed(() => !isDone.value && !isOpen.value)
    const scores = computed<Score[]>(() => Object.values(props.day.day.scores || {}))
    const accruedScores = computed<AccruedScore[]>(() => Object.values(props.day.status?.scores || {}))
    const points = computed(() => scores.value.reduce((count, score) => count + (score.isEnabled ? score.amount : 0), 0) || 0)
    const accruedPoints = computed(() => {
      return accruedScores.value.reduce((count, aScore) => {
        const isEnabled = scores.value.find(score => score.type === aScore.type)?.isEnabled || false
        return count + (isEnabled ? aScore.amount : 0)
      }, 0)
    })
    const dayLink = computed(() => {
      if (isClose.value) {
        return ''
      }
      return props.day.link
    })

    const statusClass = computed(() => {
      if (isDone.value) {
        return 'done'
      }
      if (isOpen.value) {
        return 'open'
      }

      return 'close'
    })

    const statusText = computed(() => {
      if (isDone.value) {
        return i18n.global.t('calendarPage.done')
      }
      if (isOpen.value) {
        return i18n.global.t('calendarPage.passNow')
      }

      if (props.day.openDate) {
        return `${i18n.global.t('calendarPage.closeUntil')}: ${props.day.openDate}`
      }
      return i18n.global.t('calendarPage.close')
    })

    const pointsText = computed(() => {
      if (props.day.isGamificationEnabled) {
        let text = getNumberDecliner({
          n: points.value,
          single: i18n.global.t('pointsDecliner.point'),
          multiple: i18n.global.t('pointsDecliner.points'),
          multiple2: i18n.global.t('pointsDecliner.points2')
        })

        if (accruedPoints.value) {
          text = getNumberDecliner({
            n: accruedPoints.value,
            single: i18n.global.t('pointsDecliner.point'),
            multiple: i18n.global.t('pointsDecliner.points'),
            multiple2: i18n.global.t('pointsDecliner.points2')
          })
          if (accruedPoints.value === points.value) {
            return `${accruedPoints.value} ${text}`
          }
          return `${accruedPoints.value}/${points.value} ${text}`
        } else if (isDone.value) {
          return ''
        }

        return `${points.value} ${text}`
      }

      return ''
    })

    const isShowPoints = computed(() => pointsText.value && points.value)

    return {
      dayLink,
      isDisabled,
      isDisabledStage,
      isDone,
      isOpen,
      statusClass,
      statusText,
      isClose,
      pointsText,
      isShowPoints,
      prettyHoursMinutes
    }
  }
})
