import { ref, computed, SetupContext } from 'vue'
import { Stream } from '@web/store/types/modules/stream'
import LocalStorage from '@web/common/LocalStorage'
import STREAM_STATUS from '@web/consts/StreamStatus'
import { getNoteCountDayRemaining, getUrlWithAuthParams } from '@web/common/Utils'
import { useStore } from '@web/store'
import { EXTEND_MODEL } from '@web/consts/CourseExtentends'
import urlParse from 'url-parse'
import { useRouter } from 'vue-router'

const DAY = 86400000
const LS_SAVEDTIME_KEY = 'acProject_close_alert_renew_'

export const emits = {
  close: (): boolean => true
}

function useAlertRenewCourseMobile ({ stream, isCritical, isWarning }: { stream: Stream, isCritical: boolean, isWarning: boolean }, emit: SetupContext<typeof emits>['emit']) {
  const store = useStore()
  const router = useRouter()

  const course = computed(() => store.getters['course/getCourseById'](stream.courseId))
  const isShowRenewBtn = computed(() => course.value?.extendModel !== EXTEND_MODEL.DISABLED_EXTEND)
  const savedTime = {
    get () {
      return LocalStorage.get(`${LS_SAVEDTIME_KEY}${stream.id}`) ? Number(LocalStorage.get(`${LS_SAVEDTIME_KEY}${stream.id}`)) : 0
    },
    set (newTime: string) {
      LocalStorage.set(`${LS_SAVEDTIME_KEY}${stream.id}`, newTime)
    }
  }

  const showAlert = ref(false)
  const showRenewCourse = ref(false)

  const text = stream.status !== STREAM_STATUS.ENDED && stream.status !== STREAM_STATUS.STOPPED ? getNoteCountDayRemaining(stream, true) : ''

  function checkShowAlert () {
    let show = true
    if (stream.status === STREAM_STATUS.STOPPED || stream.paymentSubscriptionId) {
      showAlert.value = false
      return
    }
    if (savedTime.get() && ((Date.now() - savedTime.get()) < DAY)) {
      show = false
    }
    showAlert.value = isCritical || (isWarning && show)
  }

  function handlerRenew () {
    if (stream.isOptionalPurchase) {
      router.push({ name: 'subscriptions', params: { warning: 'true' } })
    } else if (course.value?.extendModel === EXTEND_MODEL.NEW_BUY_EXTEND && course.value?.extendPageUrl) {
      const urlInstance = urlParse(course.value.extendPageUrl, true)
      urlInstance.set('query', {
        ...urlInstance.query,
        utm_source: 'lk',
        utm_medium: 'extend_button'
      })
      window.open(getUrlWithAuthParams(urlInstance.toString()), '_blank')?.focus()
    } else {
      showRenewCourse.value = true
    }
  }

  function close () {
    savedTime.set(Date.now().toString())
    showAlert.value = false
    emit('close')
  }

  return {
    isShowRenewBtn,
    showRenewCourse,
    text,
    showAlert,
    checkShowAlert,
    handlerRenew,
    close
  }
}

export default useAlertRenewCourseMobile
