
import { computed, defineComponent, PropType, ref } from 'vue'
import { ClickOutside } from '../directives/click-outside'
import { AttacheToElement, Location } from '../directives/attache-to-element'

export default defineComponent({
  name: 'AcMenu',
  directives: {
    ClickOutside,
    AttacheToElement
  },
  props: {
    modelValue: {
      type: Boolean,
      default: true
    },
    location: {
      type: String as PropType<Location.top | Location.bottom | Location.left | Location.right>,
      default: 'bottom'
    },
    manual: { // Открытие/закрытие меню управляется вручную через modelValue
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup (props, ctx) {
    const refActivator = ref<HTMLElement>()
    const refMenu = ref<HTMLElement>()
    const isShow = ref(false)
    const isShowHandler = computed(() => props.manual ? props.modelValue : isShow.value)

    function onOpenMenu () {
      if (!props.manual) {
        isShow.value = true
        ctx.emit('update:modelValue', true)
      }
    }

    function onCloseMenu () {
      if (!props.manual) {
        isShow.value = false
        ctx.emit('update:modelValue', false)
      }
    }

    function onToggleMenu () {
      if (!props.manual) {
        ctx.emit('update:modelValue', !isShowHandler.value)
        isShow.value = !isShow.value
      }
    }

    return {
      refActivator,
      refMenu,
      isShow,
      isShowHandler,
      onOpenMenu,
      onCloseMenu,
      onToggleMenu
    }
  }
})
