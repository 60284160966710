import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "lCommon lCalendar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_as_alert_renew_course_mobile = _resolveComponent("as-alert-renew-course-mobile")!
  const _component_as_banner = _resolveComponent("as-banner")!
  const _component_ac_calendar = _resolveComponent("ac-calendar")!
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_bottom_navigation = _resolveComponent("bottom-navigation")!
  const _component_as_breadcrumb = _resolveComponent("as-breadcrumb")!
  const _component_as_header_main = _resolveComponent("as-header-main")!
  const _component_as_menu = _resolveComponent("as-menu")!
  const _component_as_banners_top = _resolveComponent("as-banners-top")!
  const _component_as_row = _resolveComponent("as-row")!
  const _component_as_spacer = _resolveComponent("as-spacer")!
  const _component_as_footer = _resolveComponent("as-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mq_responsive, {
      class: "lCalendar_wrapper",
      target: "mobile-tablet"
    }, {
      default: _withCtx(() => [
        (_ctx.course)
          ? (_openBlock(), _createBlock(_component_as_header_mobile, {
              key: 0,
              title: _ctx.course.caption,
              "custom-back": "",
              onGoBack: _ctx.goBack,
              "onMenu:toggle": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpenMenu = $event))
            }, null, 8, ["title", "onGoBack"]))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_as_alert_renew_course_mobile, { stream: _ctx.stream }, null, 8, ["stream"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannersBeforeStage, (banner, i) => {
            return (_openBlock(), _createBlock(_component_as_banner, {
              key: `bannerBeforeStage${i}`,
              banner: banner,
              platform: _ctx.bannersPlatforms.mobile,
              position: _ctx.bannersPosition,
              placement: _ctx.bannersPlacements.beforeStage
            }, null, 8, ["banner", "platform", "position", "placement"]))
          }), 128)),
          _createVNode(_component_ac_calendar, { class: "lCalendar_calendar" })
        ], 512), [
          [_vShow, !_ctx.isOpenMenu]
        ])
      ]),
      _: 1
    }),
    _withDirectives(_createVNode(_component_mq_responsive, { target: "mobile" }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_bottom_navigation, {
          stream: _ctx.stream,
          "current-page": "course",
          course: _ctx.course
        }, null, 8, ["stream", "course"]), [
          [_vShow, !_ctx.isOpenMenu]
        ])
      ]),
      _: 1
    }, 512), [
      [_vShow, !_ctx.isOpenMenu]
    ]),
    _createVNode(_component_mq_responsive, {
      class: "lCommon bg-blue",
      target: "desktop"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_main, null, {
          breadcrumb: _withCtx(() => [
            _createVNode(_component_as_breadcrumb, { title: _ctx.title }, null, 8, ["title"])
          ]),
          _: 1
        }),
        _createVNode(_component_as_row, null, {
          menu: _withCtx(() => [
            _createVNode(_component_as_menu, {
              modelValue: _ctx.menuMini,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.menuMini) = $event)),
              minify: true,
              menu: _ctx.menu,
              type: "sidebar"
            }, null, 8, ["modelValue", "menu"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannersSidebar, (banner, i) => {
              return (_openBlock(), _createBlock(_component_as_banner, {
                key: `bannerSidebar${i}`,
                banner: banner,
                platform: _ctx.bannersPlatforms.desktop,
                position: _ctx.bannersPosition,
                placement: _ctx.bannersPlacements.sidebar
              }, null, 8, ["banner", "platform", "position", "placement"]))
            }), 128))
          ]),
          content: _withCtx(() => [
            _createVNode(_component_as_banners_top, {
              banners: _ctx.bannersTop,
              position: _ctx.bannersPosition,
              placement: _ctx.bannersPlacements.top,
              platform: _ctx.bannersPlatforms.desktop
            }, null, 8, ["banners", "position", "placement", "platform"]),
            _createVNode(_component_as_row, { class: "-inner" }, {
              content: _withCtx(() => [
                _createVNode(_component_ac_calendar)
              ]),
              _: 1
            }),
            _createVNode(_component_as_spacer),
            _createVNode(_component_as_banners_top, {
              banners: _ctx.bannersBottom,
              position: _ctx.bannersPosition,
              placement: _ctx.bannersPlacements.bottom,
              platform: _ctx.bannersPlatforms.desktop
            }, null, 8, ["banners", "position", "placement", "platform"])
          ]),
          _: 1
        }),
        _createVNode(_component_as_footer)
      ]),
      _: 1
    })
  ]))
}