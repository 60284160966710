
import { defineComponent, onMounted, computed } from 'vue'

import useCalendar from '@web/composition/useCalendar'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from '@web/store'
import { useMeta } from '@web/common/vueMeta'

import i18n from '@web/plugins/i18n'
import sentry from '@web/common/sentry'
import Swal from 'sweetalert2'
import Logger from '@web/common/Logger'
import { performanceEnd } from '@web/common/Utils'
import FirebasePerformance from '@web/services/firebasePerformance'
import OneSignal from '@web/common/OneSignal'
import Analytics from '@web/services/Analytics/Analytics'

import AcOnBoarding from '@ui-kit/components/AcOnBoarding.vue'
import AcButton from '@ui-kit/components/AcButton.vue'
import AsPreloader from '@web/components/AsPreloader.vue'

import Stages from './Stages.vue'
import Stage from './Stage.vue'
import Days from './Days.vue'
import Day from './Day.vue'
import Alert from './Alert.vue'
import Homework from './Homework.vue'
import STAGE_STATUS from '@web/consts/StageStatus'

export default defineComponent({
  name: 'AcCalendar',
  components: {
    AcButton,
    Homework,
    AsPreloader,
    AcOnBoarding,
    Stages,
    Stage,
    Days,
    Day,
    Alert
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const title = i18n.global.t('scheduleCourse')
    // TODO Wait for onBoarding
    const stepsOnBoarding = []

    useMeta({
      title
    })

    const {
      loaded,
      menu,
      checkId,
      course,
      stages,
      fetchData,
      stream,
      setPageData,
      saveLocationToContinue,
      bannersTop,
      bannersSidebar,
      bannersBottom,
      bannersBeforeStage,
      bannersPlatforms,
      bannersPlacements,
      bannersPosition
    } = useCalendar({ router })

    const isGamificationEnabled = computed(() => Boolean(course.value?.gamificationEnabled) && !stream.value?.courseFullAccess)

    async function init () {
      let calendarLoading = performance.now()
      const startLoading = Date.now()
      try {
        await fetchData()
      } catch (error) {
        if (['calendar', 'demo-calendar'].includes(String(route.name) || '')) {
          sentry.captureException(error)

          await Swal.fire({
            title: i18n.global.t('error.error'),
            text: i18n.global.t('error.calendar'),
            icon: 'error'
          })

          Logger.error(error)

          checkId()
        }
      } finally {
        calendarLoading = performanceEnd(calendarLoading)
        Logger.info(`Calendar loaded: ${calendarLoading} ms`)
        loaded.value = true
        if (stream.value && course.value) {
          setPageData({
            userCourseStreamId: stream.value.id,
            streamId: stream.value.streamId,
            courseId: course.value.id,
            courseTitle: course.value.title,
            startDate: stream.value.startDate,
            endDate: stream.value.endDate
          })

          FirebasePerformance.record({
            traceName: 'load_calendar',
            startTime: startLoading,
            options: {
              attributes: {
                userCourseStreamId: stream.value.id.toString(),
                streamId: (stream.value.streamId || '').toString(),
                courseId: (course.value?.id || '').toString(),
                courseTitle: course.value?.title || ''
              }
            }
          })

          if (course.value.categoryId) {
            OneSignal.sendTag(`course_category_${course.value.categoryId}`, '1')
          }
        }

        Analytics.send({
          category: 'ux.performance',
          action: 'load_calendar',
          label: calendarLoading.toString()
        })

        if (course.value) {
          const user = store.getters['user/user']
          Analytics.send({
            category: 'ux.training',
            action: 'open_calendar',
            label: `${user.id}:${course.value.id}`
          })
        }

        saveLocationToContinue()
      }
    }

    function goToChat () {
      if (stream.value) {
        router.push({
          name: 'chat',
          params: {
            chatId: stream.value?.chatChannelId
          }
        })
      }
    }

    function checkStageIsOpenForHomeworks (currentStage) {
      const currentIndex = stages.value.findIndex(s => s.stage.id === currentStage.stage.id)
      const prevStage = stages.value[currentIndex - 1]

      if (prevStage?.status.status === STAGE_STATUS.CLOSE) {
        return 'close'
      }

      return prevStage ? prevStage.status.requestDays.length > 0 || prevStage.status.requestHomeworks.length > 0 ? 'close' : 'open' : 'open'
    }

    onMounted(() => {
      init()
    })

    return {
      loaded,
      title,
      menu,
      course,
      stream,
      stages,
      bannersTop,
      bannersSidebar,
      bannersBottom,
      bannersBeforeStage,
      bannersPlatforms,
      bannersPlacements,
      bannersPosition,
      stepsOnBoarding,
      isGamificationEnabled,
      goToChat,
      checkStageIsOpenForHomeworks
    }
  }
})
