import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "acProgress" }
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 0,
  class: "acProgress_text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("progress", {
      class: "acProgress_progress",
      max: "100",
      value: _ctx.modelValue
    }, _toDisplayString(_ctx.modelValue) + "% ", 9, _hoisted_2),
    (_ctx.showText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.modelValue) + "%", 1))
      : _createCommentVNode("", true)
  ]))
}