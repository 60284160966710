
import { defineComponent, PropType } from 'vue'

import { Stream } from '@web/store/types/modules/stream'

import AcProgress from '@ui-kit/components/AcProgress.vue'
import AcMenu from '@ui-kit/components/AcMenu.vue'

export default defineComponent({
  name: 'CalendarStage',
  components: {
    AcMenu,
    AcProgress
  },
  props: {
    stream: {
      type: Object as PropType<Stream>,
      required: true
    },
    stage: {
      type: Object,
      required: true
    }
  },
  setup () {
    const showMenu = false

    return {
      showMenu
    }
  }
})
